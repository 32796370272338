/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import { isMultiColorActive, defaultColor, themeColorStorageKey, isDarkSwitchActive } from './constants/defaultValues';
// import 'bootstrap/dist/css/bootstrap.min.css';
const color =
    (isMultiColorActive || isDarkSwitchActive) && localStorage.getItem(themeColorStorageKey)
        ? localStorage.getItem(themeColorStorageKey)
        : defaultColor;

localStorage.setItem(themeColorStorageKey, color);
// replace console.* for disable log on production
if (
    process.env.NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test' ||
    process.env.NODE_ENV === 'staging'
) {
    //console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    console.warn = () => {};
}

const render = () => {
    import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
        require('./AppRenderer');
    });
};
render();
