/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
    { id: 'en', name: 'English', direction: 'ltr' },
    { id: 'ar', name: 'عربي', direction: 'rtl' },
];

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_firebase_api_key,
    authDomain: 'gogo-react-login.firebaseapp.com',
    databaseURL: 'https://gogo-react-login.firebaseio.com',
    projectId: 'gogo-react-login',
    storageBucket: 'gogo-react-login.appspot.com',
    messagingSenderId: '216495999563',
};

export const searchPath = '#';
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.redruby';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;
export const colors = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel',
];
export const debouncedJSTime = 100;
export const debouncedAPITime = 400;
export const PROCESS = [
    'MAINTENANCE',
    'PA-REVIEW-MAIN-PROCESS',
    'EO-REVIEW-MAIN-PROCESS',
    'SC-REVIEW-MAIN-PROCESS',
    'MUN-REVIEW-PROCESS',
    'AUDIT-REVIEW-PROCESS',
    'RO-GENERAL-REVIEW-PROCESS',
    'AUDIT-REAPPROVE-PROCESS',
];
export const excludePendingOrganization = ['AUDIT-REVIEW-PROCESS', 'RO-GENERAL-REVIEW-PROCESS'];
export const URL_APPLICATIONS_LIST = '/app/applications/details';
export const FORWARD_EXTERNAL_CONSULT_HEAD = 'FORWARD_EXTERNAL_CONSULT_HEAD';
export const FORWARD_CONSULT = 'FORWARD_CONSULT';
export const PERMIT_REQ_NUMBER_REGEX = /^[A-Z]{2}\/\d+\/\d{4}$/;
